import React, { Component } from "react";
import { string } from "prop-types";

class RightArrow extends Component {
  render() {
    const { className } = this.props;
    return (
      <svg className={className} width="50px" height="10px" viewBox="0 0 50 10">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M0,4.35355339 L20,4.35355339"
            stroke="#fff"
            data-arrow-link-tail=""
            style={{ stroke: "#ffffff" }}
          />
        </g>

        <g stroke="none" fill="none" fillRule="evenodd">
          <polygon
            fill="#fff"
            fillRule="nonzero"
            points="18.7994138 4.35355339 15.1529672 0.707106781 15.860074 0 20.2136274 4.35355339 15.860074 8.70710678 15.1529672 8"
            data-arrow-link-head=""
            data-svg-origin="15.15296745300293 0"
            transform="matrix(1,0,0,1,0,0)"
            style={{ fill: "#ffffff" }}
          />
        </g>
      </svg>
    );
  }
}

RightArrow.propTypes = {
  className: string
};

export default RightArrow;
