import React, { Component } from "react";
import { bool, func } from "prop-types";
import cx from "classnames";
import styles from "./HeaderButton.module.scss";

class HeaderButton extends Component {
  render() {
    const { menuOpen, onMenuToggle } = this.props;
    return (
      <button
        className={`${cx(styles.icon)} ${
          menuOpen ? cx(styles.icon_active) : ""
        }`}
        type="button"
        onClick={onMenuToggle}
      >
        <div className={cx(styles.closed)}>
          <svg
            width="21px"
            height="16px"
            viewBox="0 0 21 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-314.000000, -23.000000)" fill="#FFFFFF">
                <g transform="translate(324.500000, 31.000000) rotate(-180.000000) translate(-324.500000, -31.000000) translate(314.000000, 23.000000)">
                  <polygon points="0.108 8.9021 20.25 8.9021 20.25 6.9021 0.108 6.9021" />
                  <polygon points="0 2.0001 8.167 2.0001 8.167 0.0001 0 0.0001" />
                  <polygon points="0.061 15.8041 14.929 15.8041 14.929 13.8041 0.061 13.8041" />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div className={cx(styles.opened)}>
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Mobile_Menu_Open"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              transform="translate(-317.000000, -23.000000)"
            >
              <g
                id="Group-2"
                transform="translate(325.000000, 31.000000) scale(-1, 1) translate(-325.000000, -31.000000) translate(317.000000, 23.000000)"
                fill="#FFFFFF"
              >
                <polygon
                  id="Fill-1"
                  transform="translate(3.618528, 12.681479) rotate(-225.000000) translate(-3.618528, -12.681479) "
                  points="0.0485282275 13.6814787 7.18852809 13.6814787 7.18852809 11.6814787 0.0485282275 11.6814787"
                />
                <polygon
                  id="Fill-1-Copy"
                  transform="translate(12.768522, 3.481478) rotate(-225.000000) translate(-12.768522, -3.481478) "
                  points="9.19852212 4.48147794 16.338522 4.48147794 16.338522 2.48147794 9.19852212 2.48147794"
                />
                <polygon
                  id="Fill-1"
                  transform="translate(8.172328, 8.077672) scale(-1, 1) rotate(-225.000000) translate(-8.172328, -8.077672) "
                  points="-1.89767155 9.07767185 18.2423278 9.07767185 18.2423278 7.07767185 -1.89767155 7.07767185"
                />
              </g>
            </g>
          </svg>
        </div>
      </button>
    );
  }
}

HeaderButton.propTypes = {
  menuOpen: bool,
  onMenuToggle: func.isRequired
};

export default HeaderButton;
