import React, { Component } from "react";
import { bool, func, string } from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import { LANGUAGES } from "../../constants";
import styles from "./LanguageNav.module.scss";

class LanguageNav extends Component {
  render() {
    const { isMobile, language, onClick } = this.props;

    const currentLanguage = LANGUAGES.find(l => {
      return l.id === language;
    });

    return (
      <nav className={`${cx(styles.nav)}`}>
        {currentLanguage && (
          <div className={cx(styles.label)}>
            {currentLanguage.chooseLanguage}:
          </div>
        )}
        <ul className={cx(styles.list)}>
          {currentLanguage && (
            <li key={currentLanguage.to}>
              <Link
                to={currentLanguage.to}
                className={cx(styles.active_link)}
                onClick={onClick}
              >
                <span>{currentLanguage.name}</span>
              </Link>
            </li>
          )}
          {LANGUAGES.map(l => {
            if (l.id === language) {
              return null;
            }

            return (
              <li key={l.to}>
                <Link to={l.to} onClick={onClick}>
                  <span>{l.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

LanguageNav.propTypes = {
  isMobile: bool,
  language: string,
  onClick: func
};

export default LanguageNav;
