import React, { Component, Fragment } from "react";
import { bool, func, object } from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";
import { LANGUAGES } from "../../constants";
import HeaderButton from "../HeaderButton";
import LanguageDropdown from "../LanguageDropdown";
import Logo from "../Logo";
import styles from "./Header.module.scss";
import RightArrow from "../RightArrow";
import { withRouter } from "react-router";

class Header extends Component {
  render() {
    const { location, menuOpen, onMenuToggle } = this.props;
    const isHome = location.pathname === "/";

    // Split the current path into parts so we can get the language and slug of the current page to pass down to the nav
    const pathParts = location.pathname.split("/");
    const language = pathParts.length > 1 ? pathParts[1] : null;

    const currentLanguage = LANGUAGES.find(l => {
      return l.id === language;
    });

    const className = isHome
      ? `${cx(styles.header)}`
      : `${cx(styles.header)} ${cx(styles.headerWithNav)}`;

    return (
      <header className={className}>
        <div className={`${cx(styles.container)} container`}>
          <div className="outer-container">
            <Link to="/" className={`${cx(styles.logo)} float-left`}>
              <Logo />
            </Link>
            {isHome &&
              false && (
                <nav className={`float-right`}>
                  <a
                    href="https://www.inscape.tv/"
                    className={cx(styles.nav_link)}
                  >
                    Visit inscape.tv North America
                    <RightArrow className={cx(styles.right_arrow)} />
                  </a>
                </nav>
              )}
            {!isHome && (
              <Fragment>
                <HeaderButton menuOpen={menuOpen} onMenuToggle={onMenuToggle} />
                <nav className={`${cx(styles.page_nav)}  float-right`}>
                  {currentLanguage && (
                    <a
                      href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                      className={cx(styles.nav_link)}
                    >
                      {currentLanguage.contact}
                    </a>
                  )}
                  <LanguageDropdown language={language} isMobile={false} />
                </nav>
                <div className="clearfix" />
              </Fragment>
            )}
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  location: object.isRequired,
  menuOpen: bool,
  onMenuToggle: func.isRequired,
  pages: object.isRequired
};

export default withRouter(Header);
