import React, { Component } from "react";
import { bool, func, object } from "prop-types";
import cx from "classnames";
import LanguageNav from "../LanguageNav";
import PageNav from "../PageNav";
import styles from "./MobileMenu.module.scss";
import { withRouter } from "react-router";

class MobileMenu extends Component {
  render() {
    const { location, pages, menuOpen, onMenuToggle } = this.props;

    // Split the current path into parts so we can get the language and slug of the current page to pass down to the nav
    const pathParts = location.pathname.split("/");
    const language = pathParts.length > 1 ? pathParts[1] : null;
    const slug = pathParts.length > 2 ? pathParts[2] : null;
    const pagesForLanguage = pages && language ? pages[language] : null;
    return (
      <div
        className={`${cx(styles.menu)} ${
          menuOpen ? cx(styles.menu_active) : null
        }`}
      >
        <PageNav
          pages={pagesForLanguage}
          currentPageSlug={slug}
          language={language}
          isMobile={true}
          onClick={onMenuToggle}
        />
        <LanguageNav
          language={language}
          isMobile={true}
          onClick={onMenuToggle}
        />
      </div>
    );
  }
}

MobileMenu.propTypes = {
  location: object,
  menuOpen: bool,
  onMenuToggle: func.isRequired,
  pages: object
};

export default withRouter(MobileMenu);
