import React, { Component } from "react";
import { bool, object } from "prop-types";
import cx from "classnames";
import Loader from "react-loader-spinner";
import Error from "../Error";
import styles from "./Page.module.scss";

class Page extends Component {
  render() {
    const { error, loading, page } = this.props;
    return (
      <section className={cx(styles.page)}>
        <div className={`${styles.page_container} container`}>
          {page && (
            <article
              className={cx(styles.page_content)}
              dangerouslySetInnerHTML={{ __html: page.body }}
            />
          )}
          {loading && (
            <div className="text-center">
              <Loader type="Oval" color="#1BCEB2" height="100" width="100" />
            </div>
          )}
          {!page && !loading && error && <Error />}
        </div>
      </section>
    );
  }
}

Page.propTypes = {
  loading: bool,
  page: object
};

export default Page;
