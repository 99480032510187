import React, { Component, Fragment } from "react";
import { object } from "prop-types";
import cx from "classnames";
import PageNav from "../PageNav";
import styles from "./IntroSection.module.scss";
import { withRouter } from "react-router";

class IntroSection extends Component {
  render() {
    const { location, page, pages } = this.props;

    // Split the current path into parts so we can get the language and slug of the current page to pass down to the nav
    const pathParts = location.pathname.split("/");
    const language = pathParts.length > 1 ? pathParts[1] : null;
    const slug = pathParts.length > 2 ? pathParts[2] : null;
    const pagesForLanguage = pages && language ? pages[language] : null;

    return (
      <Fragment>
        {page && (
          <section className={cx(styles.container)}>
            <div className="outer-container">
              <article className={cx(styles.inner)}>
                <div className={`${cx(styles.inner_container)} container`}>
                  <h1 className={cx(styles.title)}>{page.title}</h1>
                  <div
                    className={cx(styles.body)}
                    dangerouslySetInnerHTML={{ __html: page.body }}
                  />
                  <div className="clearfix" />
                </div>
                <div className=" d-none d-sm-block">
                  <PageNav
                    pages={pagesForLanguage}
                    currentPageSlug={slug}
                    isMobile={false}
                    language={language}
                  />
                </div>
              </article>
            </div>
          </section>
        )}
      </Fragment>
    );
  }
}

IntroSection.propTypes = {
  location: object,
  page: object,
  pages: object
};

export default withRouter(IntroSection);
