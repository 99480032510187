import React, { Component } from "react";
import axios from "axios";
import { func, object } from "prop-types";
import cx from "classnames";
import { PAGE_TYPE_INTRO_POPUP, PAGE_TYPE_PRIVACY } from "../../constants";
import IntroSection from "../../components/IntroSection";
import Page from "../../components/Page";
import styles from "./LanguageSection.module.scss";
import { withRouter } from "react-router";

class LanguageSection extends Component {
  state = {
    error: false,
    shouldShowModal: false,
    modalClosed: false,
    loading: false,
    page: null
  };

  // Close the modal
  closeModal = () => {
    this.setState({
      modalClosed: true
    });
  };

  loadPagesForLanguage = async language => {
    const { onPagesLoad } = this.props;
    this.setState({ loading: true }, async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${language}/api/pages.json`
        );
        this.setState(
          {
            loading: false
          },
          () => {
            onPagesLoad(language, response.data.data);
          }
        );
      } catch (e) {
        console.error(e);
        this.setState({
          loading: false,
          error: true
        });
      }
    });
  };

  getPageBySlug = (language, slug) => {
    const { pages } = this.props;

    if (pages && pages[language]) {
      return pages[language].find(p => {
        return p.slug === slug;
      });
    }
  };

  getPageByType = (language, type) => {
    const { pages } = this.props;

    if (pages && pages[language]) {
      return pages[language].find(p => {
        return p.type.value === type;
      });
    }
  };

  componentDidMount() {
    const { match, pages } = this.props;
    const { language } = match.params;
    if (!pages[language]) {
      this.setState(
        {
          shouldShowModal: true
        },
        () => {
          this.loadPagesForLanguage(language);
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    const prevMatch = prevProps.match;
    const prevLanguage =
      prevMatch && prevMatch.params ? prevMatch.params.language : null;

    const { match, pages } = this.props;
    const { language } = match.params;
    if (
      prevLanguage &&
      language &&
      prevLanguage !== language &&
      !pages[language]
    ) {
      this.loadPagesForLanguage(language);
    }
  }

  render() {
    const { error, loading } = this.state;
    const { match, pages } = this.props;
    const { language, slug } = match.params;

    // Get the intro page from the list of pages
    const intro =
      pages && pages[language]
        ? pages[language].find(page => {
            return page.type.value === PAGE_TYPE_INTRO_POPUP;
          })
        : null;

    // Get the page to show to the user.  If there is no slug, just show the privacy policy page
    const page =
      pages && pages[language]
        ? slug
          ? this.getPageBySlug(language, slug)
          : this.getPageByType(language, PAGE_TYPE_PRIVACY)
        : null;

    // No longer used since the modal is now a fixed section
    //const isModalOpen = false && !!(!modalClosed && shouldShowModal && intro);

    return (
      <main className={cx(styles.language_section)}>
        <IntroSection page={intro} pages={pages} />
        <Page page={page} loading={loading} error={error} />
      </main>
    );
  }
}

LanguageSection.propTypes = {
  match: object.isRequired,
  onPagesLoad: func.isRequired,
  pages: object.isRequired
};

export default withRouter(LanguageSection);
