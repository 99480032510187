import React, { Component } from "react";

class Logo extends Component {
  render() {
    return (
      <svg
        viewBox="0 0 149 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Inscape Logo</title>
        <defs>
          <polygon
            id="path-1"
            points="0 27.7201474 148.782087 27.7201474 148.782087 0.368568421 0 0.368568421"
          />
        </defs>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="HEADER" transform="translate(-91.000000, -31.000000)">
            <g id="white-logo">
              <g transform="translate(91.000000, 31.000000)">
                <path
                  d="M35.3036503,7.14906316 C32.1850803,10.2334842 32.1850803,15.2528526 35.3036503,18.3372737 L36.5034728,19.5235895 L39.2458178,16.8112737 L38.0459953,15.6245895 C37.2689603,14.8564316 36.8413303,13.8329579 36.8413303,12.7431684 C36.8413303,11.6530105 37.2689603,10.6299053 38.0459953,9.86137895 L44.9018578,3.08095789 L42.1595128,0.368273684 L35.3036503,7.14906316 Z"
                  id="Fill-1"
                  fill="#6090B3"
                />
                <path
                  d="M50.214937,19.5237368 C53.333507,16.4393158 53.333507,11.4199474 50.214937,8.33552632 L49.0151145,7.14884211 L46.2727695,9.86152632 L47.472592,11.0482105 C48.249627,11.8163684 48.677257,12.8398421 48.677257,13.9296316 C48.677257,15.0194211 48.249627,16.0428947 47.472592,16.8114211 L40.6167295,23.5918421 L43.3590745,26.3041579 L50.214937,19.5237368 Z"
                  id="Fill-4"
                  fill="#6090B3"
                />
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-7" />
                <polygon
                  id="Fill-6"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                  points="0 22.9255158 3.393475 22.9255158 3.393475 3.74698947 0 3.74698947"
                />
                <path
                  d="M139.086546,7.10319474 C141.973793,7.10319474 144.411806,9.03408947 145.154571,11.6583526 L133.018893,11.6583526 C133.761658,9.03408947 136.199671,7.10319474 139.086546,7.10319474 M139.086546,3.74724737 C133.732231,3.74724737 129.391488,8.04045789 129.391488,13.3361421 C129.391488,18.6321947 133.732231,22.9254053 139.086546,22.9254053 C142.573891,22.9254053 145.629881,21.1035632 147.338166,18.3706158 L142.796273,18.3706158 C141.754763,19.1236684 140.472991,19.5694579 139.086546,19.5694579 C136.199671,19.5694579 133.761658,17.6385632 133.018893,15.0146684 L148.631113,15.0146684 C148.728336,14.4694053 148.781976,13.9090368 148.781976,13.3361421 C148.781976,8.04045789 144.441233,3.74724737 139.086546,3.74724737"
                  id="Fill-8"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M115.453879,19.5694211 C111.979199,19.5694211 109.151924,16.7731053 109.151924,13.3364737 C109.151924,9.89947368 111.979199,7.10315789 115.453879,7.10315789 C118.928932,7.10315789 121.755834,9.89947368 121.755834,13.3364737 C121.755834,16.7731053 118.928932,19.5694211 115.453879,19.5694211 M115.453879,3.74721053 C110.099192,3.74721053 105.758822,8.04042105 105.758822,13.3364737 L105.758822,13.3375789 L105.758822,27.72 L109.151924,27.72 L109.151924,20.6205263 C110.847172,22.0562632 113.047157,22.9253684 115.453879,22.9253684 C120.808567,22.9253684 125.149309,18.6321579 125.149309,13.3364737 C125.149309,8.04042105 120.808567,3.74721053 115.453879,3.74721053"
                  id="Fill-9"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M90.9722107,19.5694211 C87.4975307,19.5694211 84.6702557,16.7731053 84.6702557,13.3364737 C84.6702557,9.89947368 87.4975307,7.10315789 90.9722107,7.10315789 C94.4472632,7.10315789 97.2741657,9.89947368 97.2741657,13.3364737 C97.2741657,16.7731053 94.4472632,19.5694211 90.9722107,19.5694211 Z M100.667641,13.3364737 C100.667641,8.04042105 96.3268982,3.74721053 90.9722107,3.74721053 C85.6178957,3.74721053 81.2771532,8.04042105 81.2771532,13.3364737 C81.2771532,18.6321579 85.6178957,22.9253684 90.9722107,22.9253684 C93.3793057,22.9253684 95.5792907,22.0562632 97.2741657,20.6205263 L97.2741657,22.9253684 L100.667641,22.9253684 L100.667641,13.3375789 L100.667641,13.3364737 Z"
                  id="Fill-10"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M27.8742123,13.3363263 C27.8742123,8.04027368 23.5334698,3.74706316 18.1787823,3.74706316 C12.8244673,3.74706316 8.48335225,8.04027368 8.48335225,13.3363263 L8.48335225,22.9255895 L11.8768273,22.9255895 L11.8768273,13.3363263 C11.8768273,9.89969474 14.7041023,7.10337895 18.1787823,7.10337895 C21.6538348,7.10337895 24.4807373,9.89969474 24.4807373,13.3363263 L24.4807373,22.9255895 L27.8742123,22.9255895 L27.8742123,13.3370632 L27.8742123,13.3363263 Z"
                  id="Fill-11"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
                <path
                  d="M72.645844,16.6925684 C71.5242465,18.4208316 69.5656415,19.5695684 67.3395815,19.5695684 C63.8649015,19.5695684 61.0376265,16.7732526 61.0376265,13.3362526 C61.0376265,9.89962105 63.8649015,7.10330526 67.3395815,7.10330526 C69.5656415,7.10330526 71.5242465,8.25167368 72.645844,9.98030526 L76.4233665,9.98030526 C75.0473515,6.33956842 71.5004065,3.74698947 67.3395815,3.74698947 C61.984894,3.74698947 57.644524,8.04056842 57.644524,13.3362526 C57.644524,18.6323053 61.984894,22.9255158 67.3395815,22.9255158 C71.5004065,22.9255158 75.0473515,20.3329368 76.4233665,16.6925684 L72.645844,16.6925684 Z"
                  id="Fill-12"
                  fill="#FFFFFF"
                  mask="url(#mask-2)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default Logo;
