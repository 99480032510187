import React, { Component } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styles from "./Landing.module.scss";
import { LANGUAGES } from "../../constants";

class Landing extends Component {
  componentDidMount() {
    document.body.classList.add("landing");
  }
  componentWillUnmount() {
    document.body.classList.remove("landing");
  }

  render() {
    return (
      <main className={cx(styles.landing)}>
        <section className={cx(styles.container)}>
          <h1 className={cx(styles.title)}>Welcome to Inscape Europe</h1>
          <h4 className={cx(styles.subtitle)}>Choose a language</h4>

          <ul className={`${cx(styles.list)} list-unstyled`}>
            {LANGUAGES.map(l => {
              return (
                <li key={l.id}>
                  <Link to={l.to}>
                    <span>{l.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      </main>
    );
  }
}

export default Landing;
