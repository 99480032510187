export const PAGE_TYPE_INTRO_POPUP = "introPopup";
export const PAGE_TYPE_TERMS_SERVICE = "termsOfService";
export const PAGE_TYPE_DISABLE_ACR = "howToDisableAcr";
export const PAGE_TYPE_PRIVACY = "privacyPolicy";

export const LANGUAGES = [
  {
    id: "francais",
    name: "Francais",
    to: "/francais",
    contact: "Contact",
    chooseLanguage: "Changer de langue"
  },
  {
    id: "deutsche",
    name: "Deutsche",
    to: "/deutsche",
    contact: "Kontakt",
    chooseLanguage: "Sprache ändern"
  },
  {
    id: "nederlands",
    name: "Nederlands",
    to: "/nederlands",
    contact: "Contact",
    chooseLanguage: "Verander taal"
  },
  {
    id: "english",
    name: "English (UK)",
    to: "/english",
    contact: "Contact",
    chooseLanguage: "Change Language"
  }
];
