import React, { Component } from "react";
import cx from "classnames";
import styles from "./Error.module.scss";

class Error extends Component {
  render() {
    return (
      <article className={cx(styles.error)}>
        <h1>503: Service Unavailable</h1>
        <p>
          Looks like we are having some server issues. In the meantime, feel
          free to{" "}
          <a href="https://www.inscape.tv/">visit inscape.tv North America.</a>{" "}
        </p>
      </article>
    );
  }
}

export default Error;
