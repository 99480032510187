import React, { Component } from "react";
import cx from "classnames";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../Header";
import Landing from "../../views/Landing";
import LanguageSection from "../../views/LanguageSection";
import MobileMenu from "../MobileMenu";
import styles from "./App.module.scss";

class App extends Component {
  state = {
    pages: {},
    menuOpen: false
  };

  toggleMenu = () => {
    const { menuOpen } = this.state;
    this.setState({
      menuOpen: !menuOpen
    });
  };

  /**
   * Captures the response from the api and stores the pages in the state
   * @param language
   * @param pages
   */
  onPagesLoad = (language, pages) => {
    this.setState({
      pages: { ...this.state.pages, [language]: pages }
    });
  };

  render() {
    const { menuOpen, pages } = this.state;
    return (
      <Router>
        <div className={cx(styles.app)}>
          <MobileMenu
            menuOpen={menuOpen}
            pages={pages}
            onMenuToggle={this.toggleMenu}
          />
          <Header
            pages={pages}
            menuOpen={menuOpen}
            onMenuToggle={this.toggleMenu}
          />
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route
              path="/:language"
              exact
              render={props => (
                <LanguageSection
                  pages={pages}
                  {...props}
                  onPagesLoad={this.onPagesLoad}
                />
              )}
            />
            <Route
              path="/:language/:slug"
              render={props => (
                <LanguageSection
                  pages={pages}
                  {...props}
                  onPagesLoad={this.onPagesLoad}
                />
              )}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
