import React, { Component } from "react";
import { array, bool, func, string } from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";
import {
  LANGUAGES,
  PAGE_TYPE_DISABLE_ACR,
  PAGE_TYPE_PRIVACY,
  PAGE_TYPE_TERMS_SERVICE
} from "../../constants";
import styles from "./PageNav.module.scss";

class PageNav extends Component {
  getPageByType = type => {
    const { pages } = this.props;

    if (pages) {
      return pages.find(p => {
        return p.type.value === type;
      });
    }
  };

  render() {
    const { currentPageSlug, isMobile, language, onClick } = this.props;

    const privacy = this.getPageByType(PAGE_TYPE_PRIVACY);
    const terms = this.getPageByType(PAGE_TYPE_TERMS_SERVICE);
    const acr = this.getPageByType(PAGE_TYPE_DISABLE_ACR);

    const currentLanguage = LANGUAGES.find(l => {
      return l.id === language;
    });

    return (
      <nav
        className={
          isMobile
            ? cx(styles.page_nav, styles.page_nav_mobile)
            : cx(styles.page_nav)
        }
      >
        <div className={`${styles.page_nav_container} container`}>
          <ul className={cx(styles.list)}>
            {privacy && (
              <li>
                <Link
                  to={`/${language}/${privacy.slug}`}
                  onClick={onClick}
                  className={
                    !currentPageSlug || currentPageSlug === privacy.slug
                      ? cx(styles.active)
                      : null
                  }
                >
                  {privacy.title}
                </Link>
              </li>
            )}
            {terms && (
              <li>
                <Link
                  to={`/${language}/${terms.slug}`}
                  onClick={onClick}
                  className={
                    currentPageSlug === terms.slug ? cx(styles.active) : null
                  }
                >
                  {terms.title}
                </Link>
              </li>
            )}
            {acr && (
              <li>
                <Link
                  to={`/${language}/${acr.slug}`}
                  onClick={onClick}
                  className={
                    currentPageSlug === acr.slug ? cx(styles.active) : null
                  }
                >
                  {acr.title}
                </Link>
              </li>
            )}
            {isMobile &&
              currentLanguage && (
                <li>
                  <a
                    href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                    onClick={onClick}
                  >
                    {currentLanguage.contact}
                  </a>
                </li>
              )}
          </ul>
        </div>
      </nav>
    );
  }
}

PageNav.propTypes = {
  currentPageSlug: string,
  isMobile: bool,
  language: string,
  onClick: func,
  pages: array
};

export default PageNav;
