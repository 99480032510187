import React, { Component } from "react";
import { bool, func, string } from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import nanoid from "nanoid";
import { LANGUAGES } from "../../constants";
import styles from "./LanguageDropdown.module.scss";

class LanguageDropdown extends Component {
  state = {
    active: false,
    id: nanoid()
  };

  isDescendant = (parent, child) => {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  };

  closeMenu = (e, force) => {
    const { active, id } = this.state;
    if (
      force ||
      (active && !this.isDescendant(document.getElementById(id), e.target))
    ) {
      this.setState({
        active: false
      });
    }
  };

  componentDidMount = () => {
    document.addEventListener("click", this.closeMenu);
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.closeMenu);
  };

  render() {
    const { isMobile, language, onClick } = this.props;
    const { active, id } = this.state;

    const currentLanguage = LANGUAGES.find(l => {
      return l.id === language;
    });

    return (
      <div
        id={id}
        className={`${cx(styles.dropdown)} ${active ? cx(styles.active) : null}
          ${isMobile ? cx(styles.dropdown_mobile) : null}`}
        onMouseEnter={() => {
          this.setState({ active: true });
        }}
        onMouseLeave={() => {
          this.setState({ active: false });
        }}
      >
        {currentLanguage && (
          <div className={cx(styles.label)}>
            <button
              onClick={e => {
                setTimeout(() => {
                  this.setState({ active: true });
                }, 200);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {currentLanguage.name}
            </button>
          </div>
        )}
        <div className={cx(styles.list)}>
          <ul className={active ? cx(styles.active) : null}>
            {currentLanguage && (
              <li key={currentLanguage.to}>
                <Link
                  to={currentLanguage.to}
                  className={cx(styles.active_link)}
                  onClick={e => {
                    this.closeMenu(e, true);
                    if (onClick) {
                      onClick();
                    }
                  }}
                >
                  <span>{currentLanguage.name}</span>
                </Link>
              </li>
            )}
            {LANGUAGES.map(l => {
              if (l.id === language) {
                return null;
              }

              return (
                <li key={l.to}>
                  <Link
                    to={l.to}
                    onClick={e => {
                      this.closeMenu(e, true);
                      if (onClick) {
                        onClick();
                      }
                    }}
                  >
                    <span>{l.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

LanguageDropdown.propTypes = {
  isMobile: bool,
  language: string,
  onClick: func
};

export default LanguageDropdown;
